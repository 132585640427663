function NotFound() {
  return (
    <>
      <h1>404</h1>
      <span className="loader"></span>
    </>
  );
}

export default NotFound;
